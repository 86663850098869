import { OperatorName } from 'constants/operators';
import { VehicleType } from 'types';

// @ts-ignore (require.context is a webpack-specific method)
const reqContextOperatorLogosMonoSvgs = require.context(
    '../assets/operator-logos-mono',
    true,
    /\.svg$/
);

// @ts-ignore (require.context is a webpack-specific method)
const reqVehicleImageSvg = require.context(
    '../assets/vehicles',
    true,
    /\.svg$/
);

const vehicleTypeFileString = {
    [VehicleType.Bike]: 'bike',
    [VehicleType.Scooter]: 'scooter',
    [VehicleType.SeatedScooter]: 'seated-scooter',
    [VehicleType.Car]: 'car',
    [VehicleType.eBike]: 'bike',
    [VehicleType.Bus]: 'bus',
    [VehicleType.Truck]: 'truck',
    [VehicleType.Moped]: 'moped',
    [VehicleType.CargoBike]: 'cargo-bike',
    [VehicleType.CargoEbike]: 'cargo-ebike',
    [VehicleType.DeliveryRobot]: 'bike',
    [VehicleType.Motorcycle]: 'moped',
    [VehicleType.Unknown]: 'bike',
    [VehicleType.Pedestrian]: 'pedestrian'
};

export const getVehicleTypeSvgForOperator = (
    operatorName: OperatorName,
    vehicleType: VehicleType
): string => {
    const vehicleName = vehicleTypeFileString[vehicleType] ?? 'bike';
    try {
        return reqVehicleImageSvg(
            `./${vehicleName}-${operatorName.toLowerCase()}.svg`
        );
    } catch {
        return require(`../assets/vehicles/${vehicleName}-generic.svg`);
    }
};

export const getLogoSvgForOperator = (
    operatorName: OperatorName
): string | null => {
    try {
        console.log('logo file', './' + operatorName.toLowerCase() + '.svg');
        return reqContextOperatorLogosMonoSvgs(
            './' + operatorName.toLowerCase() + '.svg'
        );
    } catch {
        return null;
    }
};
